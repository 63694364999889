import { BairroLogradouro, Logradouro, Region } from '@sq/data/schemas'

import supplyHttp from '@/lib/http/supply.http'

export async function fetchBairros(): Promise<Region[]> {
    return supplyHttp
        .get<Region[] | null>('api/v1/addresses/count-bairros')
        .then((res) => res || [])
        .catch(() => [])
}

export async function fetchLogradouros(): Promise<Logradouro[]> {
    return supplyHttp
        .get<Logradouro[] | null>('api/v1/addresses/count-logradouros')
        .then((res) => res || [])
        .catch(() => [])
}

export async function fetchBairroLogradouros(): Promise<BairroLogradouro[]> {
    return supplyHttp
        .get<BairroLogradouro[] | null>('api/v1/addresses/count-bairro-logradouro')
        .then((res) => res || [])
        .catch(() => [])
}
