import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
    // Skip validation when Building on CI
    skipValidation: process.env.SKIP_ENV_VALIDATION === 'true',

    /*
     * Serverside Environment variables, not available on the client.
     * Will throw if you access these variables on the client.
     */
    server: {
        AUTH_KEYCLOAK_ID: z.string().min(1),
        AUTH_KEYCLOAK_SECRET: z.string().min(1),
        AUTH_KEYCLOAK_ISSUER: z.string().url(),

        NEXTAUTH_SECRET: z.string().min(1),

        SITE_URL: z.string().optional().default('https://www.suaquadra.com.br'),
    },

    /*
     * Environment variables available on the client (and server).
     *
     * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
     */
    client: {
        NEXT_PUBLIC_SUPPLY_URL: z.string().min(1).transform(parseEnvApiPath),
        NEXT_PUBLIC_USER_URL: z.string().min(1).transform(parseEnvApiPath),

        NEXT_PUBLIC_TRACKING_URL: z.string().optional().default('https://t.qdra.io/'),

        NEXT_PUBLIC_AMPLITUDE_API_KEY: z.string().optional().default('57f74509f127ee72a64658dfb9e39e99'),

        NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: z.string(),

        NEXT_PUBLIC_USER_AUTH_URL_PREVIEW: z.string().optional().default('https://user.qdra.io/api/v1/otp'),

        NEXT_PUBLIC_KEYCLOAK_CLIENT: z.string().optional().default('ssr'),
    },

    /*
     * Due to how Next.js bundles environment variables on Edge and Client,
     * we need to manually destructure them to make sure all are included in bundle.
     *
     * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
     */
    runtimeEnv: {
        NEXT_PUBLIC_SUPPLY_URL: process.env.NEXT_PUBLIC_SUPPLY_URL,
        NEXT_PUBLIC_USER_URL: process.env.NEXT_PUBLIC_USER_URL,
        NEXT_PUBLIC_TRACKING_URL: process.env.NEXT_PUBLIC_TRACKING_URL,
        NEXT_PUBLIC_AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
        NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,

        NEXT_PUBLIC_USER_AUTH_URL_PREVIEW: process.env.NEXT_PUBLIC_USER_AUTH_URL_PREVIEW,

        NEXT_PUBLIC_KEYCLOAK_CLIENT: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT,

        AUTH_KEYCLOAK_ID: process.env.AUTH_KEYCLOAK_ID,
        AUTH_KEYCLOAK_SECRET: process.env.AUTH_KEYCLOAK_SECRET,
        AUTH_KEYCLOAK_ISSUER: process.env.AUTH_KEYCLOAK_ISSUER,

        NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,

        SITE_URL: process.env.SITE_URL,
    },
})

function parseEnvApiPath(path: string | undefined) {
    if (!path) {
        return ''
    }

    if (path.endsWith('/')) {
        path = path.slice(0, -1)
    }

    if (path.endsWith('/v1')) {
        path = path.slice(0, -3)
    }

    if (path.endsWith('/api')) {
        path = path.slice(0, -4)
    }

    return path
}
