import isNetworkError from '@common.js/is-network-error'

import { cleanAuth, getAuthPreview } from '@/lib/@core/auth/auth.usecase'

interface HttpClient {
    get<T>(path: string, authenticated?: boolean): Promise<T>

    delete<T, U>(path: string, body: T, authenticated?: boolean): Promise<U>

    post<T, U>(path: string, body: T, authenticated?: boolean): Promise<U>

    postForm<T extends Record<string, string>, U>(path: string, body: T, authenticated?: boolean): Promise<U>

    patch<T, U>(path: string, body: T, authenticated?: boolean): Promise<U>

    put<T, U>(path: string, body: T, authenticated?: boolean): Promise<U>
}

type PostMethods = 'POST' | 'PUT' | 'PATCH' | 'DELETE'
type GetMethods = 'GET'

export class HttpError extends Error {
    status: number
    data: any

    constructor(message: string, status: number, data: any) {
        super(message)
        this.status = status
        this.data = data
    }
}

export class NetworkError extends Error {
    constructor(cause?: Error) {
        super('Network Error', { cause })
    }
}

export class UnauthorizedError extends HttpError {
    constructor(data: any) {
        super('Unauthorized', 401, data)
    }
}

async function checkAuth() {
    const auth = await getAuthPreview()
    if (!auth) {
        return Promise.reject('Unauthenticated')
    }
    return auth
}

async function handleUnauthorizedResponse(res: Response) {
    if (res.status === 401) {
        window.location.href = `/login?redirectUrl=${encodeURIComponent(window.location.href)}`
        cleanAuth()
        // handled in .catch
        const errorResponse = await res.json().catch(() => res.body)
        throw new UnauthorizedError(errorResponse)
    }

    return res
}

export async function handleErrorResponse(res: Response) {
    if (res.ok) {
        return await res.json()
    }

    const errorResponse = await res.json().catch(() => res.body)
    throw new HttpError(`${res.statusText} - ${res.url}`, res.status, errorResponse)
}

function handleNetworkError(err: unknown): never {
    if (isNetworkError(err)) {
        throw new NetworkError(err)
    }

    throw err
}

function poster(baseUrl: string, method: PostMethods) {
    return async function <T, U>(path: string, body: T, authenticated = false): Promise<{ data: U } & U> {
        let auth = null
        if (authenticated) {
            auth = await checkAuth()
        }

        const correctBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl
        const correctPath = path.startsWith('/') ? path.slice(1) : path

        // TODO: Move to axios
        return fetch(`${correctBaseUrl}/${correctPath}`, {
            method,
            body: JSON.stringify(body),
            headers: {
                ...(auth && auth.access_token ? { Authorization: `Bearer ${auth.access_token}` } : {}),
            },
            cache: 'no-store',
        })
            .catch(handleNetworkError)
            .then(handleUnauthorizedResponse)
            .then(handleErrorResponse)
    }
}

function posterForm(baseUrl: string, method: PostMethods) {
    return async function <U>(
        path: string,
        body: Record<string, string>,
        authenticated = false
    ): Promise<{ data: U } & U> {
        let auth = null
        if (authenticated) {
            auth = await checkAuth()
        }

        return fetch(`${baseUrl}/${path}`, {
            method,
            body: new URLSearchParams(body),
            headers: {
                ...(auth && auth.access_token ? { Authorization: `Bearer ${auth.access_token}` } : {}),
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .catch(handleNetworkError)
            .then(handleUnauthorizedResponse)
            .then(handleErrorResponse)
    }
}

function getter(baseUrl: string, method: GetMethods) {
    return async function <T>(path: string, authenticated = false): Promise<T> {
        let auth = null
        if (authenticated) {
            auth = await checkAuth()
        }

        return await fetch(`${baseUrl}/${path}`, {
            method,
            headers: {
                ...(auth && auth.access_token ? { Authorization: `Bearer ${auth.access_token}` } : {}),
            },
            cache: 'no-store',
        })
            .catch(handleNetworkError)
            .then(handleUnauthorizedResponse)
            .then(handleErrorResponse)
    }
}

export function buildHttpClient(baseUrl: string): HttpClient {
    return {
        get: getter(baseUrl, 'GET'),
        post: poster(baseUrl, 'POST'),
        postForm: posterForm(baseUrl, 'POST'),
        delete: poster(baseUrl, 'DELETE'),
        put: poster(baseUrl, 'PUT'),
        patch: poster(baseUrl, 'PATCH'),
    }
}
