'use client'

import { getDeviceId, getUserId, init } from '@amplitude/analytics-browser'
import React, { createContext, useEffect, useState } from 'react'

import { env } from '@/lib/env'

const AmplitudeContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isReady, setIsReady] = useState(false)
    const [amplitudeUserId, setAmplitudeUserId] = useState<string | undefined>(undefined)
    const [amplitudeDeviceId, setAmplitudeDeviceId] = useState<string | undefined>(undefined)

    useEffect(() => {
        const amplitudeApiKey = env.NEXT_PUBLIC_AMPLITUDE_API_KEY

        if (!amplitudeApiKey) {
            return
        }

        init(amplitudeApiKey, {
            serverUrl: 'https://www.suaquadra.com.br/collect/amplitude',
            defaultTracking: {
                sessions: true,
                fileDownloads: false,
                formInteractions: true,
                pageViews: true,
                attribution: true,
            },
        }).promise.then(() => {
            setIsReady(true)
            setAmplitudeDeviceId(getDeviceId())
            setAmplitudeUserId(getUserId())
        })
    }, [])

    return (
        <AmplitudeContext.Provider
            value={{
                isReady,
                amplitudeUserId,
                amplitudeDeviceId,
            }}
        >
            {children}
        </AmplitudeContext.Provider>
    )
}

type AmplitudeContextProps = {
    isReady: boolean
    amplitudeUserId?: string
    amplitudeDeviceId?: string
}

export const AmplitudeContext = createContext<AmplitudeContextProps>({
    isReady: false,
    amplitudeUserId: undefined,
    amplitudeDeviceId: undefined,
})

export default AmplitudeContextProvider
