'use client'

import { BairroLogradouro, Region } from '@sq/data/schemas'
import React, { createContext, useCallback, useState } from 'react'

import { fetchBairroLogradouros, fetchBairros } from '@/components/results/requests'

export const AddressesContext = createContext({
    regions: [] as Region[],
    logradouros: [] as BairroLogradouro[],
    fetchAddresses: (): void => {
        throw new Error('Make sure to wrap your component with "AddressesProvider"')
    },
})

export const AddressesProvider = ({ children }: { children: React.ReactNode }) => {
    const [regions, setRegions] = useState<Region[]>([])
    const [logradouros, setLogradouros] = useState<BairroLogradouro[]>([])

    const fetchAddresses = useCallback(() => {
        if (!regions.length) {
            fetchBairros().then(setRegions)
        }
        if (!logradouros.length) {
            fetchBairroLogradouros().then(setLogradouros)
        }
    }, [regions, logradouros])

    return (
        <AddressesContext.Provider value={{ regions, logradouros, fetchAddresses }}>
            {children}
        </AddressesContext.Provider>
    )
}
