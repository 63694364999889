'use client'

import { deepEqual } from 'fast-equals'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const KEYS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_adgroup',
    'utm_ad',
    'utm_term',
    'utm_pagepath',
    'utm_device',
    'gclid',
    'fbclid',
]

export const MKT_COOKIE_KEY = 'sq-mkt-cookie'
const EXPIRES_IN_DAYS = 20

export const setUpMktCookies = (currentLocation: URL) => {
    // Load query parameters from the current URL
    const url = new URLSearchParams(currentLocation.search)
    const utm = Object.fromEntries(url.entries())

    // Remove non-UTM keys
    Object.keys(utm).forEach((k) => {
        if (!KEYS.includes(k)) {
            delete utm[k]
        }
    })

    // Load cached UTM parameters
    const cachedUtmQs = Cookies.get(MKT_COOKIE_KEY)
    const sqclid = Cookies.get('_sqclid')
    const cachedUtmUrl = new URLSearchParams(cachedUtmQs)
    const cachedUtm = Object.fromEntries(cachedUtmUrl.entries())

    if (sqclid) {
        cachedUtm['sqclid'] = sqclid
        utm['sqclid'] = sqclid
    }

    // If cached UTM exists and no new UTM values, return cached UTM
    if (Object.keys(cachedUtm).length > 0 && Object.keys(utm).length == 0) {
        return cachedUtm
    }

    // If cached UTM and new UTM values exist and they are equal, return cached UTM
    if (Object.keys(cachedUtm).length > 0 && Object.keys(utm).length > 0 && deepEqual(cachedUtm, utm)) {
        return cachedUtm
    }

    // Merge UTM parameters with cached UTM values, overriding cached ones with new ones
    const mergedUtm = { ...cachedUtm, ...utm }

    // Insert updated UTM parameters into cookies
    Cookies.set(MKT_COOKIE_KEY, new URLSearchParams(mergedUtm).toString(), {
        expires: EXPIRES_IN_DAYS,
        path: '/',
    })

    return mergedUtm
}

export function useMarketingCookies() {
    const [trackings, setTrackings] = useState({})

    useEffect(() => {
        setTrackings(setUpMktCookies(new URL(window.location.href)))
    }, [])

    return trackings
}
